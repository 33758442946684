<template>
  <FlagDetails :content="content"></FlagDetails>
</template>

<script>
  import FlagDetails from '@/views/components/flag/FlagDetails';
  import { FLAG_CONTENT } from '@/core/constants/enums';

  export default {
    name: 'FlagCastingPostDetails',
    components: {
      FlagDetails
    },
    data: () => ({
      content: FLAG_CONTENT.CASTING
    })
  };
</script>

<style></style>
